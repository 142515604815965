import { MusicVideo } from "@/libs/ytMusic/models";
import { Room, SavedMusic } from "@/types/room";
import { User } from "@/types/user";
import {
  createTypedHooks,
  action,
  Action,
  createStore,
  persist,
} from "easy-peasy";

export interface StoreModel {
  currentUser: {
    value?: User;
    setUser: Action<any, User | undefined>;
  };
  currentRoom: {
    value?: Room;
    setRoom: Action<any, Room | undefined>;
  };
  currentSuggestions: {
    value?: MusicVideo[];
    setSuggestions: Action<any, MusicVideo[]>;
  };
  savedMusics: {
    value: SavedMusic[];
    setSavedMusics: Action<any, SavedMusic[]>;
  };
  clearSearchOrder: {
    value?: boolean;
    setClearSearchOrder: Action<any, boolean>;
  };
}

const typedHooks = createTypedHooks<StoreModel>();

const storeModel: StoreModel = {
  currentUser: persist(
    {
      value: undefined,
      setUser: action((state: any, payload) => {
        state.value = payload;
      }),
    },
    { storage: "localStorage" }
  ),
  currentRoom: {
    value: undefined,
    setRoom: action((state: any, payload: Room | undefined) => {
      state.value = payload;
    }),
  },
  currentSuggestions: {
    value: undefined,
    setSuggestions: action((state: any, payload) => {
      state.value = payload;
    }),
  },
  savedMusics: {
    value: [],
    setSavedMusics: action((state: any, payload: SavedMusic[]) => {
      state.value = payload;
    }),
  },
  clearSearchOrder: {
    value: false,
    setClearSearchOrder: action((state: any, payload) => {
      state.value = payload;
    }),
  },
};

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export const store = createStore(storeModel);
