import { extendTheme } from "@chakra-ui/react";

const fonts = {
  heading: "Lato",
  body: "Open Sans",
};

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const colors = {
  purple: {
    "50": "#F4ECF9",
    "100": "#E0C9EE",
    "200": "#CCA6E3",
    "300": "#B883D8",
    "400": "#A460CD",
    "500": "#913DC2",
    "600": "#74319B",
    "700": "#572574",
    "800": "#3A184E",
    "900": "#1D0C27",
  },
  orange: {
    "50": "#FFF0E6",
    "100": "#FED5B8",
    "200": "#FEBB8B",
    "300": "#FDA05E",
    "400": "#FD8530",
    "500": "#FC6A03",
    "600": "#CA5502",
    "700": "#974002",
    "800": "#652B01",
    "900": "#321501",
  },
};

const components = {
  NumberInput: {
    defaultProps: {
      focusBorderColor: "orange.200",
    },
  },
  Input: {
    defaultProps: {
      focusBorderColor: "orange.200",
    },
  },
  Button: {
    defaultProps: {
      focusBorderColor: "orange.200",
    },
  },
};
const theme = extendTheme({
  //@ts-ignore
  config,
  fonts,
  colors,
  components,
});
export default theme;
