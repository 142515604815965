import { Capacitor } from "@capacitor/core";

declare const window: any;

export const getFirebaseCloudFunctionsBaseUrl = () => {
  let baseUrl = `https://europe-west3-turnup-v3.cloudfunctions.net`;
  if (
    typeof window !== "undefined" &&
    (window.location.hostname === "localhost" ||
      window.location.hostname.startsWith("192") ||
      window.location.hostname.startsWith("172")) &&
    !window.location.href.startsWith("capacitor")
  ) {
    baseUrl = `http://${window.location.hostname}:5001/turnup-v3/europe-west3`;
  }
  return baseUrl;
};

export const isElectron = () => {
  if (typeof window === "undefined") return;
  return window.isElectron;
};

export const isDesktopBrowser = () =>
  !isElectron() &&
  !Capacitor.isNativePlatform() &&
  (getOS() === "Windows" || getOS() === "macOS");

export const isMobileBrowser = () =>
  !Capacitor.isNativePlatform() && (getOS() === "iOS" || getOS() === "Android");

export const isIOS = () => getOS() === "iOS";
export const isAndroid = () => getOS() === "Android";

export const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "darwin"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "macOS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
};
