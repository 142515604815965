import "focus-visible/dist/focus-visible";
import { ChakraProvider } from "@chakra-ui/react";
import { StoreProvider } from "easy-peasy";
import React, { useEffect } from "react";
import theme from "../theme";
import "@/assets/styles/index.css";
import "@/assets/styles/Loading.css";
import "@/assets/styles/MusicItem.css";
import "@/assets/styles/Player.css";
import "@/assets/styles/Home.css";
import "@/assets/styles/Club.css";
import "@/assets/styles/Playlist.css";
import Head from "next/head";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Clipboard } from "@capacitor/clipboard";
import { App } from "@capacitor/app";
import { useRouter } from "next/router";
import { isElectron } from "@/services/utils";
import { store } from "@/store";

type Props = {
  Component: React.ComponentType;
  pageProps: {
    [key: string]: unknown;
  };
};

declare const window: any;

export default function MyApp({ Component, pageProps }: Props): JSX.Element {
  const { replace, asPath } = useRouter();
  const { ...componentProps } = pageProps;

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  });

  useEffect(() => {
    (async () => {
      const attemptToJoinRoomFromClipboardInput = async () => {
        const result = await Clipboard.read();
        const splittedResult = result.value.split("jamhouse://");
        if (splittedResult.length === 2) {
          const slug = "/" + splittedResult.pop();
          if (asPath !== slug) {
            replace(slug);
            await Clipboard.write({ string: "" });
          }
        }
      };
      const attemptToJoinRoomFromDeepLink = async (url: string) => {
        const slug = url.split(".app/");
        if (slug.length === 2) {
          return replace(`/${slug.pop()}`);
        }
      };

      if (Capacitor.isNativePlatform()) {
        StatusBar.setStyle({
          style: Style.Dark,
        });
        StatusBar.setBackgroundColor({ color: "#1D0C27" });
        const launchUrl = await App.getLaunchUrl();
        if (launchUrl?.url) {
          await attemptToJoinRoomFromDeepLink(launchUrl.url);
        }
        App.addListener("appStateChange", (state) => {
          if (state.isActive) {
            attemptToJoinRoomFromClipboardInput();
          }
        });
        App.addListener("appUrlOpen", (data: any) =>
          attemptToJoinRoomFromDeepLink(data.url)
        );
      }

      if (isElectron()) {
        window.ipcRenderer.on("join-room", async (_: any, data: any) => {
          return replace(`/${data}`);
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <title>Jamhouse - Listen to music with friends</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </Head>
      <StoreProvider store={store}>
        <ChakraProvider theme={theme}>
          <Component {...componentProps} />
        </ChakraProvider>
      </StoreProvider>
    </>
  );
}
